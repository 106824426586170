<template>
  <div class="content">
    <div class="bannerView">
      <img class="img" :src="banner.banner" alt="" />
      <div class="bannerMain">
        <router-link to="/about" class="link"
          ><i class="el-icon-arrow-left"></i>關於博銳</router-link
        >
        <div class="txtView">
          <h3>{{ banner.title }}</h3>
          <span>{{ banner.keywords }}</span>
          <!-- <div
            v-if="banner.content"
            class="con"
            v-html="banner.content.replace(/\\r\\n/g, '<br>')"
          ></div> -->
          <div
            v-if="banner.content"
            class="con html-view"
            v-html="banner.content.replace(/\n/g, '<br>')"
          ></div>
        </div>
      </div>
    </div>
    <div class="main">
      <el-row v-if="list.length != 0">
        <div
          v-for="(item, index) of list"
          :key="index"
          :class="index > 2 ? 'min_col' : ''"
        >
          <el-col :span="24" v-if="index == 0">
            <div
              :class="`item item_one animate__animated ${item.animate}`"
              @click="switchView(item, index)"
            >
              <div class="imgView">
                <img :src="item.pic" alt="" />
              </div>
              <div class="txtView">
                <div class="upView">
                  <span>{{ item.sub_title }}</span>
                </div>
                <h3>{{ item.title }}</h3>
                <div class="txtCon">{{ item.content }}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8" v-else-if="index == 1">
            <div
              :class="`item item_two animate__animated ${item.animate}`"
              @click="switchView(item, index)"
            >
              <div class="imgView">
                <img :src="item.pic" alt="" />
              </div>
              <div class="txtView">
                <div class="upView">
                  <span>{{ item.sub_title }}</span>
                </div>
                <h3>{{ item.title }}</h3>
              </div>
            </div>
          </el-col>
          <el-col :span="16" v-else-if="index == 2" class="dr">
            <div
              :class="`item item_three animate__animated ${item.animate}`"
              @click="switchView(item, index)"
            >
              <div class="imgView">
                <img :src="item.pic" alt="" />
              </div>
              <div class="txtView">
                <div class="upView">
                  <span>{{ item.sub_title }}</span>
                </div>
                <h3>{{ item.title }}</h3>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-else>
            <div
              :class="`item item_four animate__animated ${item.animate}`"
              @click="switchView(item, index)"
            >
              <div class="imgView">
                <img :src="item.pic" alt="" />
              </div>
              <div class="txtView">
                <div class="upView">
                  <span>{{ item.sub_title }}</span>
                </div>
                <h3>{{ item.title }}</h3>
              </div>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>
    <div class="subscribeNewsView">
      <subscribe-news />
    </div>
  </div>
</template>

<script>
import subscribeNews from "@/components/subscribeNews";
import { mapState } from "vuex";
export default {
  name: "OperationBaseDetailed",
  components: {
    subscribeNews,
  },
  data() {
    return {
      id: this.$route.query.id,

      banner: {},
      list: [],
    };
  },
  computed: {
    ...mapState(["screenWidth"]),
  },
  mounted() {
    this.getData();
  },
  methods: {
    /**
     * 切换数据
     */
    switchView(item, index) {
      if (index != 0) {
        this.list.unshift(this.list.splice(index, 1)[0]);
      }
    },
    /**
     * 处理数据
     */
    handleData(arr) {
      let animateArr = [
          "animate__bounceInDown",
          "animate__bounceInLeft",
          "animate__bounceInRight",
          "animate__bounceInUp",
          "animate__rotateIn",
          "animate__rotateInDownLeft",
        ],
        arrData = [];

      arr.map((item, index) => {
        arrData.push({
          ...item,
          animate: this.screenWidth > 768 ? animateArr[index] : "",
        });
      });
      return arrData;
    },
    getData() {
      this.$http.operationBaseDetails({ id: this.id }).then((res) => {
        if (res.code == 200) {
          this.banner = res.data.banner;
          this.list = this.handleData(res.data.content);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
@import "@/assets/style/news.scss";
.content {
  @include respond-to(md) {
    padding-top: 30px;
  }
  @include respond-to(sm) {
    padding-top: 15px;
  }
}
.bannerView {
  position: relative;
  margin-bottom: 80px;
  overflow: hidden;
  @include respond-to(md) {
    display: none;
  }
  .img {
    width: 100%;
    display: block;
    @include respond-to(lg) {
      height: 300px;
      width: auto;
    }
  }
  .bannerMain {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding-left: 270px;
    padding-top: 30px;
    @include respond-to(max1439) {
      padding-left: 100px;
    }

    .link {
      color: #fff;
      font-size: 14px;
      display: inline-block;
      align-items: center;
      i {
        margin-right: 5px;
      }
    }
    .txtView {
      margin-top: 30px;
      // width: 400px;
      color: #fff;
      @include respond-to(max1439) {
        margin-top: 30px;
      }
      h3 {
        font-size: 40px;
        margin-bottom: 10px;
        line-height: 1;
        font-weight: normal;
        @include respond-to(max1439) {
          font-size: 36px;
          margin-bottom: 10px;
        }
        @include respond-to(lg) {
          font-size: 26px;
        }
      }
      span {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      .con {
        font-size: 18px;
        line-height: 1.4;
        @include respond-to(max1439) {
          font-size: 16px;
        }
        @include respond-to(lg) {
          font-size: 14px;
        }
      }
    }
  }
}
.main {
  overflow: hidden;
}
</style>